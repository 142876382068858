<template>
  <div>
    <AtomLogo />
    <MoleculeCard size="md">
      <template slot="header">
        <AtomButton
          variant="link"
          @click="$router.go(-1)"
        >
          <arrow-left-icon />{{ $t('action.back') }}
        </AtomButton>
        <AtomButton
          to="/login"
          color="primary"
          class="ms-auto"
        >
          {{ $t('action.back_home') }}
        </AtomButton>
      </template>
      <h1>Consent to process Personal Data</h1>
      <h3 class="card-title">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit vitae sint laboriosam nisi adipisci quod amet rem, esse officia beatae, voluptate repellat non aut. Sunt eligendi voluptatem beatae labore nesciunt?.</h3>
      <div class="markdown">
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet eos possimus dolore, commodi id, labore ab nihil enim doloribus corrupti saepe sapiente nostrum tenetur, aliquam a repellat deleniti eius facilis?</p>
        <AtomDivider />
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet eos possimus dolore, commodi id, labore ab nihil enim doloribus corrupti saepe sapiente nostrum tenetur, aliquam a repellat deleniti eius facilis?</p>
        <ul>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet eos possimus dolore, commodi id, labore ab nihil enim doloribus corrupti saepe sapiente nostrum tenetur, aliquam a repellat deleniti eius facilis?</li>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet eos possimus dolore, commodi id, labore ab nihil enim doloribus corrupti saepe sapiente nostrum tenetur, aliquam a repellat deleniti eius facilis?</li>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet eos possimus dolore, commodi id, labore ab nihil enim doloribus corrupti saepe sapiente nostrum tenetur, aliquam a repellat deleniti eius facilis?</li>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet eos possimus dolore, commodi id, labore ab nihil enim doloribus corrupti saepe sapiente nostrum tenetur, aliquam a repellat deleniti eius facilis?</li>
        </ul>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet eos possimus dolore, commodi id, labore ab nihil enim doloribus corrupti saepe sapiente nostrum tenetur, aliquam a repellat deleniti eius facilis?</p>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet eos possimus dolore, commodi id, labore ab nihil enim doloribus corrupti saepe sapiente nostrum tenetur, aliquam a repellat deleniti eius facilis?</p>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet eos possimus dolore, commodi id, labore ab nihil enim doloribus corrupti saepe sapiente nostrum tenetur, aliquam a repellat deleniti eius facilis?</p>
        <ul>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit esse ex illo reiciendis voluptate. Eaque, quisquam dignissimos molestiae unde dolor facilis? Reiciendis, ex corporis alias animi numquam fuga temporibus cupiditate.;</li>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit esse ex illo reiciendis voluptate. Eaque, quisquam dignissimos molestiae unde dolor facilis? Reiciendis, ex corporis alias animi numquam fuga temporibus cupiditate.;</li>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit esse ex illo reiciendis voluptate. Eaque, quisquam dignissimos molestiae unde dolor facilis? Reiciendis, ex corporis alias animi numquam fuga temporibus cupiditate.;</li>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit esse ex illo reiciendis voluptate. Eaque, quisquam dignissimos molestiae unde dolor facilis? Reiciendis, ex corporis alias animi numquam fuga temporibus cupiditate.;</li>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit esse ex illo reiciendis voluptate. Eaque, quisquam dignissimos molestiae unde dolor facilis? Reiciendis, ex corporis alias animi numquam fuga temporibus cupiditate.;</li>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit esse ex illo reiciendis voluptate. Eaque, quisquam dignissimos molestiae unde dolor facilis? Reiciendis, ex corporis alias animi numquam fuga temporibus cupiditate..</li>
        </ul>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, et.:</p>
        <ul>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat tempore ut inventore iste unde delectus!;</li>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat tempore ut inventore iste unde delectus!.</li>
        </ul>
      </div>
    </MoleculeCard>
  </div>
</template>

<script>
import MoleculeCard from '@/components/MoleculeCard'
import AtomLogo from '@/components/AtomLogo'
import AtomButton from '@/components/AtomButton'
import AtomDivider from '@/components/AtomDivider'

export default {
  title: vm => vm.$t('pages.agreement.title'),

  components: {
    MoleculeCard,
    AtomLogo,
    AtomButton,
    AtomDivider,
  },
}
</script>
